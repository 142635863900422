<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/competence?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdName}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}/competence`">{{slideHd.title}}</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <!-- <div class="listContainer" v-html="pageCont">
              
            </div> -->
            <div class="listContainer">
              <!-- <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" class="clearfloat" :href="`${apiName}/detail?id=${item.id}`" target="_blank">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                  <a class="clearfloat"  v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul> -->
              <ul class="listImgList clearfloat">
                <li v-for="(item, index) in listData" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" class="clearfloat" :href="`${apiName}/detail?id=${item.id}`" target="_blank">
                    <div class="listImgPic">
                      <el-image
                        class="trans"
                        style="width: 100%; height: 100%"
                        :src="item.img"
                        fit="contain"></el-image>
                    </div>
                    <div class="listImgTit single-line trans">{{item.title}}</div>
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <div class="listImgPic">
                      <el-image
                        class="trans"
                        style="width: 100%; height: 100%"
                        :src="item.img"
                        fit="contain"></el-image>
                    </div>
                    <div class="listImgTit single-line trans">{{item.title}}</div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      navId: 4,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '产品中心',
        sub: 'PRODUCTS CENTER',
      },
      slideList:[
        // {
        //   title: '风控终端',
        //   id: '11111'
        // },
      ],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      listData: [],
      page: [
        '<p>云计算</p><p>云计算</p><p>云计算</p>',
        '<p>交通大数据</p><p>交通大数据</p><p>交通大数据</p> <p>交通大数据</p><p>交通大数据</p>',
        '<p>视频人工智能</p><p>视频人工智能</p><p>视频人工智能</p> <p>视频人工智能</p><p>视频人工智能</p>',
        '<p>软件研发</p><p>软件研发</p><p>软件研发</p> <p>软件研发</p><p>软件研发</p>',
        '<p>创新培育</p><p>创新培育</p><p>创新培育</p> <p>创新培育</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    _this.getSubNav()
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    // _this.pageCont = _this.page[_this.active]
    // _this.hdTitle = _this.slideList[_this.active].title
    // _this.hdName = _this.slideList[_this.active].title
    // _this.getList(_this.slideList[_this.active].id)
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
      this.pageNum = e
      this.getList(this.slideList[this.active].id, this.pageNum)
    },
    // 查寻二级菜单
    async getSubNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: _this.navId});
      _this.slideList = data.data
      if (data.data.length) {
        _this.hdTitle = _this.slideList[_this.active].name
        _this.hdName = _this.slideList[_this.active].name
        _this.getList(_this.slideList[_this.active].id, _this.pageNum)
      }
    },
    // 查列表
    async getList(id, pageNum) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: id, page: pageNum, pageSize: _this.pageSize});
      _this.listData = data.data.list
      _this.total = data.data.total
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
    },
  }
}
</script>
<style scoped>
.day {
  display: inline-block;
}
</style>